export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jezik"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovo polje je obavezno"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimalni broj znakova – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimalni broj znakova – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite valjanu adresu e-pošte"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesi+B2te važeći mobilni telefon"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite važeći datum"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Započni prijavu"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suglasnosti"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš potpis"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Očisti"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molim Vas o+B2stavite svoj potpis"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pročitao/-la sam, razumijem i slažem se s Uvjetima usluge"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da, slažem se"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne, ne slažem se"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša rezervacija"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijava"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjava"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noći"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gosti"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pritiskom na gumb slažete se <br /> s"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvjeti usluge"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavi"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogledaj detalje"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o gostima"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavi"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitani dokumenti"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaci o gostima"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skenirani dokumenti"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitajte fotografije svoje putovnice ili skenirajte svoju putovnicu da potvrdite svoj identitet. Ne pohranjujemo ove podatke."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite za unos pojedinosti za glavnog gosta"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite za unos pojedinosti za pratnju gosta"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberi državu"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo, pripremite svoju osobnu iskaznicu i nasmiješeno lice kako biste nastavili s automatskim postupkom provjere i verifikacije dokumenata. Neće trajati više od 1 minute."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitaj putnu ispravu"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skeniraj putnu ispravu"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremi"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokrenite postupak provjere"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posebni zahtjevi"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahtjev"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaćanje"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plati online sada"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaćanje po dolasku"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite kako želite platiti svoj boravak"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napomena: Osoblje hotela potvrdit će mogućnost dodatnih usluga na zahtjev naknadno. Trenutno ih ne plaćate."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš boravak"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavi"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smještaj"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatne usluge"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaćeno"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupno"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " noć"]), _normalize([_interpolate(_named("n")), " noći"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uljepšajte svoj boravak"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodajte dodatke da uljepšate svoj boravak prije dolaska i bit ćete ugodno iznenađeni\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatni prijenos"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navedite svoju zračnu luku, datum dolaska i broj leta kako bismo mogli organizirati transfer za vas"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of guests"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight number"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvala!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dragi"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša prijava prije dolaska je uspješno dovršena. Prije dolaska od nas ćete dobiti konačnu potvrdu prijave i upute. Hvala vam i veselimo se što ćemo vas ugostiti u hotelu Hoteza Demo."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakti hotela"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kliknite za kopiranje!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinate su kopirane!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaćanje nije uspjelo"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaši podaci o plaćanju nisu točni. Pokušajte ponovno ili se vratite i odaberite plaćanje po dolasku."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokušaj ponovo"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo ispunite svoje podatke kako biste pronašli svoju rezervaciju"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezime"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID rezervacije"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum dolaska"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nije pronađena rezervacija s ovim podacima. Provjerite podatke koje ste unijeli ili kontaktirajte hotel."])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at Reception"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite dokument"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite dokument koji želite skenirati"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Putovnica"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikacijska kartica"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezidentna karta"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prednja strana osobne iskaznice"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stražnja strana osobne iskaznice"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfi"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno prepoznavanje dokumenata i usklađivanje lica. Molimo provjerite točnost."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijenos vaših dokumenata..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provjera vaših dokumenata..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uklopite stražnju stranu vaše osobne iskaznice u okvir<br>i uslikajte"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sada skenirajte prednju stranu vaše osobne iskaznice"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uklopite svoj putovnicu u okvir<br>i uslikajte"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stavite svoje lice u okvir"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregledajte svoju fotografiju"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregledajte svoju fotografiju. Provjerite jesu li slova<br>jasna i ima li dobro osvjetljenje"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provjera vaše fotografije..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lice se ne podudara"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera nije pronađena"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovno snimanje"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uslikajte"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sve je gotovo!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dragi gost"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavi"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " odrasli"]), _normalize([_interpolate(_named("n")), " odrasli"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dijete"]), _normalize([_interpolate(_named("n")), " djece"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Odaberite ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronađi moju rezervaciju"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nešto je pošlo po zlu.<br>Molimo, pokušajte ponovo."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvori"])}
  }
}