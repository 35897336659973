export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["idioma"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Número mínimo de caracteres: ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Número máximo de caracteres – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un correo electrónico válido"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un teléfono móvil válido"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una fecha válida"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar registro"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimientos"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu Firma"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["claro"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, deje su firma"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leído, entendido y estoy de acuerdo con los Términos de los Servicios"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, estoy de acuerdo"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, no estoy de acuerdo"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su reserva"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hotel"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noches"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitados"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al presionar el botón, aceptas <br /> con "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos de servicio"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver detalles"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de invitados"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos cargados"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del huésped"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escaneados de documentos"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargue sus fotos de pasaporte o escanee su pasaporte para verificar su identidad. No almacenamos estos datos."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic para ingresar los detalles del invitado principal"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic para ingresar los detalles del invitado acompañante"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige un país"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, prepare su identificación y cara sonriente para continuar automáticamente con el proceso de verificación y verificación de documentos. No tomará más de 1 minuto."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir documento de viaje"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear documento de viaje"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar proceso de verificación"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitudes especiales"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pague en línea ahora"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar al llegar"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, elija cómo le gustaría pagar su estadía"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso: El personal del hotel confirmará la posibilidad de servicios adicionales a pedido más adelante. No paga por ellos en este momento."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu estancia"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamiento"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios extra"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " noche"]), _normalize([_interpolate(_named("n")), " noches"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mejora tu estadía"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega extras para alegrar tu estadía antes de llegar y te sorprenderás gratamente\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia privada"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporcione su aeropuerto, fecha de llegada y número de vuelo para que podamos organizar un traslado para usted"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aeropuerto"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de invitados"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de llegada"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de vuelo"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["querido"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su check-in previo se completó con éxito. Antes de su llegada, recibirá de nosotros un correo electrónico de confirmación de check-in final e instrucciones. Gracias y esperamos darle la bienvenida en Hoteza Demo Hotel."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos del hotel"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡haz clic para copiar!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se copiaron las coordenadas!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago fallido"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu información de pago es incorrecta. Inténtalo de nuevo o regresa y selecciona el pago a la llegada."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intentar de nuevo"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor complete sus datos para encontrar su reserva"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de reserva"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de llegada"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ninguna reserva con esta información. Consulta los datos introducidos o contacta con el hotel."])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at Reception"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar documento"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione qué documento desea escanear"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaporte"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de identificación"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de residencia"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frente del documento de identidad"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte trasera del documento de identidad"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconocimiento de documentos y coincidencia facial exitosos. Por favor, revise para asegurar precisión."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subiendo tus documentos..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validando sus documentos..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alinea la parte posterior de tu documento de identidad en el marco<br>y toma una foto"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora escanea la parte frontal de tu documento de identidad"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alinea tu pasaporte en el marco<br>y toma una foto"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coloca tu rostro en el marco"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa tu foto"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa tu foto. Asegúrate de que las letras estén<br>claras y que haya buena iluminación"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validando tu foto..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rostro no coincide"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cámara no encontrada"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturar"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Todo listo!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimado invitado"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " adulto"]), _normalize([_interpolate(_named("n")), " adultos"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " niño"]), _normalize([_interpolate(_named("n")), " niños"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elige un ", _interpolate(_named("elemento"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar mi reserva"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal.<br>Por favor, inténtalo de nuevo."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])}
  }
}