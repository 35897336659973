export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mindestanzahl an Zeichen – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximale Anzahl an Zeichen – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültige E-Mail-Adresse eingeben"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültige Mobiltelefonnummer eingeben"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültiges Datum eingeben"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in starten"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einwilligungen"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Unterschrift"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klar"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte hinterlassen Sie Ihre Unterschrift"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die Nutzungsbedingungen gelesen, verstanden und stimme ihnen zu"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ich stimme zu"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, ich stimme nicht zu"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Buchung"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einchecken"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auschecken"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächte"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gäste"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken der Taste stimmen Sie dem zu"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details anzeigen"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gästeinformationen"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgeladene Dokumente"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastdetails"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente scannen"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie Ihre Passfotos hoch oder scannen Sie Ihren Reisepass, um Ihre Identität zu überprüfen. Wir speichern diese Daten nicht."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie, um Details für den Hauptgast einzugeben"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie, um Details für den begleitenden Gast einzugeben"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Land"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bereiten Sie Ihren Ausweis und Ihr lächelndes Gesicht vor, um den automatischen Dokumentenprüfungs- und Verifizierungsprozess durchzuführen. Dies dauert nicht länger als 1 Minute."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisedokument hochladen"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisedokument scannen"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierungsprozess starten"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonderwünsche"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt online bezahlen"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Ankunft bezahlen"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie aus, wie Sie Ihren Aufenthalt bezahlen möchten"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Das Hotelpersonal wird die Möglichkeit zusätzlicher Dienstleistungen auf Anfrage später bestätigen. Sie bezahlen diese zu diesem Zeitpunkt nicht."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Aufenthalt"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkunft"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatzleistungen"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Nacht"]), _normalize([_interpolate(_named("n")), " Nächte"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbessern Sie Ihren Aufenthalt"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie Extras hinzu, um Ihren Aufenthalt noch vor Ihrer Ankunft zu verschönern, und Sie werden angenehm überrascht sein"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privater Transfer"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihren Flughafen, Ihr Ankunftsdatum und Ihre Flugnummer an, damit wir einen Transfer für Sie arrangieren können"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flughafen"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Gäste"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankunftsdatum"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flugnummer"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr geehrte Damen und Herren"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Pre-Check-in wurde erfolgreich abgeschlossen. Vor Ihrer Ankunft erhalten Sie von uns eine letzte Check-in-Bestätigungsmail und Anweisungen. Vielen Dank und wir freuen uns, Sie im Hoteza Demo Hotel begrüßen zu dürfen."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotelkontakte"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Kopieren klicken!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Koordinaten werden kopiert!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung fehlgeschlagen"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Zahlungsinformationen sind falsch. Bitte versuchen Sie es erneut oder gehen Sie zurück und wählen Sie Zahlung bei Ankunft."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre Daten ein, um Ihre Buchung zu finden"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungs-ID"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankunftsdatum"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde keine Buchung mit diesen Informationen gefunden. Überprüfen Sie die von Ihnen eingegebenen Daten oder kontaktieren Sie das Hotel."])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at Reception"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument auswählen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie aus, welches Dokument Sie scannen möchten"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepass"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausweis"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldebescheinigung"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorderseite des Ausweises"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückseite des Ausweises"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenerkennung und Gesichtsabgleich erfolgreich. Bitte überprüfen Sie auf Genauigkeit."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade deine Dokumente hoch..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung Ihrer Dokumente..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richte die Rückseite deines Ausweises am Rahmen aus<br>und mache ein Foto"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne jetzt die Vorderseite deines Ausweises"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richte deinen Reisepass am Rahmen aus<br>und mache ein Foto"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platziere dein Gesicht im Rahmen"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfe dein Foto"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfe dein Foto. Stelle sicher, dass die Buchstaben deutlich sind<br>und es gutes Licht gibt"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validiere dein Foto..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesicht passt nicht überein"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera nicht gefunden"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu aufnehmen"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto machen"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles ist erledigt!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieber Gast"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Erwachsener"]), _normalize([_interpolate(_named("n")), " Erwachsene"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Kind"]), _normalize([_interpolate(_named("n")), " Kinder"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wählen Sie ein ", _interpolate(_named("Element"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Buchung finden"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen.<br>Bitte versuche es erneut."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])}
  }
}