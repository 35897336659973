export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле обязательно к заполнению"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Минимальное количество символов – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимальное количество символов – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите корректный email"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите корректный номер телефона"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите корректную дату"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать check-in"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соглашение"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша подпись"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стереть"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, оставьте вашу подпись"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я прочитал и принимаю условия обслуживания."])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да, я согласен"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет, я не согласен"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша бронь"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отель"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заселение"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выезд"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ночей"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество гостей"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажимая на кнопку, вы соглашаетесь <br/> с "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условиями предоставления услуг"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О номере"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о гостях"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загруженные документы"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о госте"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканированные документы"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите фотографии своего паспорта или отсканируйте паспорт для подтверждения личности. Мы не храним эти данные."])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите страну"])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите, чтобы ввести данные для основного гостя"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите, чтобы ввести данные дополнительного гостя"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, приготовьтесь сделать фото документа. Это нужно для того, чтобы приступить к автоматической проверке документов и верификации. Это займет не более 1 минуты."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите документы путешественника"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сфотографируйте документы путешественника"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать процесс верификации"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальные запросы"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить онлайн"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить по прибытии"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберите способ оплаты проживания"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание: Персонал отеля подтвердит возможность предоставления дополнительных услуг по запросу позже. На данный момент вы их не оплачиваете."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка данных"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размещение"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные услуги"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 ночей"]), _normalize([_interpolate(_named("n")), " ночь"]), _normalize([_interpolate(_named("n")), " ночи"]), _normalize([_interpolate(_named("n")), " ночей"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улучшите свое пребывание"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте дополнительные услуги, чтобы скрасить ваше пребывание до приезда, и вы будете приятно удивлены"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трансфер"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, укажите свой аэропорт, дату прибытия и номер рейса, чтобы мы могли организовать для вас трансфер."])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аэропорт"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Число гостей"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата прилета"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер рейса"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо вам!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дорогой"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша предварительная регистрация была успешно завершена. Перед прибытием вы получите от нас окончательное подтверждение регистрации и инструкции. Благодарим вас и будем рады приветствовать вас в отеле Hoteza Demo."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты отеля"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кликните, чтобы скопировать!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Координаты скопированы!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платеж не прошел"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша информация об оплате неверна. Пожалуйста, попробуйте еще раз или вернитесь назад и выберите оплату по прибытии."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте еще"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, заполните свои данные, чтобы найти бронирование"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер бронирования"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата прибытия"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирования с такой информацией не обнаружено. Проверьте введенные данные или свяжитесь с отелем."])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить онлайн"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить на ресепшне"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите документ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите какой документ вы хотели бы отсканировать"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID карта"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ о резидентстве"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицевая сторона"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная сторона"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Селфи"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешное распознавание документа и сопоставление лица. Пожалуйста, проверьте на точность."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка ваших документов..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка ваших документов..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выровняйте обратную сторону вашего удостоверения в рамке<br>и сделайте фотографию"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканируйте лицевую сторону вашего удостоверения"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выровняйте ваш паспорт в рамке<br>и сделайте фотографию"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поместите ваше лицо в рамку"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте ваше фото"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте ваше фото. Убедитесь, что буквы четкие<br>и хорошее освещение"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка вашего фото..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицо не совпадает"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камера не найдена"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переснять"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделать фото"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все готово!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дорогой гость"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["нет взрослых"]), _normalize([_interpolate(_named("n")), " взрослый"]), _normalize([_interpolate(_named("n")), " взрослых"]), _normalize([_interpolate(_named("n")), " взрослых"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["нет детей"]), _normalize([_interpolate(_named("n")), " ребенок"]), _normalize([_interpolate(_named("n")), " ребенка"]), _normalize([_interpolate(_named("n")), " детей"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Выберите ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти бронирование"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так.<br>Пожалуйста, попробуйте еще раз."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
  }
}